import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import jp7 from '../public/JP7-WHITE.png';

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#333', // Dark background color
});

const StyledTypography = styled(Typography)({
  flexGrow: 1,
});

const StyledButton = styled(Button)({
  textTransform: 'none',
  color: 'white', // White text color
  fontFamily: 'Arial',
});

const StyledLogo = styled('img')({
  height: '50px', // Adjust the height as needed
  margin: '10px 10px 0 10px', // Add margin for spacing
});

export const TopBar = () => {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <StyledTypography variant="h6" component="div">
          <a href="/">
            <StyledLogo src={jp7} alt="Logo" />
          </a>
        </StyledTypography>

        <StyledButton color="inherit" component={Link} to="/">
          Home
        </StyledButton>
        <StyledButton color="inherit" component={Link} to="/campInfo">
          CampInfo
        </StyledButton>
        <StyledButton color="inherit" component={Link} to="/about">
          About
        </StyledButton>
      </Toolbar>
    </StyledAppBar>
  );
};
