import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SignUpCard } from '../components/SignUpCard';
import { styled } from '@mui/system';
import { Alert, AlertTitle } from '@mui/material';
import jamesSigning from '../public/james_signing.png';

const StyledHome = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: `calc(100vh - 72px)`,
  overflow: 'auto',

  [theme.breakpoints.down('sm')]: {
    height: `calc(100vh - 72px)`,
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 72,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: `url(${jamesSigning})`,
    backgroundSize: 'cover',
    filter: 'blur(5px)',
    zIndex: -1,
  },
}));

export const Home = () => {
  const location = useLocation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isSuccess = params.get('success') === 'true';
    setShowSuccessMessage(isSuccess);

    if (isSuccess) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [location.search]);

  return (
    <>
      {showSuccessMessage && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Your payment was successful!
        </Alert>
      )}
      <StyledHome>
        <SignUpCard />;
      </StyledHome>
    </>
  );
};
