// Import necessary dependencies
import { React, useState } from 'react';
import {
  Box,
  Card,
  Button,
  CardContent,
  Typography,
  Grid,
  Modal,
} from '@mui/material';
import { styled } from '@mui/system';
import JP7Graphic from '../public/james_info.jpg';
import Rulebook from '../public/Rulebook.pdf';

// Define the styled component
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(220, 220, 220, 1)',
  margin: 'auto',
  width: '80%',
  [theme.breakpoints.down('md')]: {
    width: '100%', // Change width to 96% on small screens
  },
  border: 'none',
  boxShadow: 'none',
  fontFamily: 'Trebuchet MS !important',
}));

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Arial',
  // fontSize: '0.5rem',
  textAlign: 'justify',
  width: '96%',
  maxWidth: '1200px',
  margin: 'auto',
  fontWeight: 'thin',
  paddingBottom: '8px',
}));

const StyledTypographyList = styled(Typography)(() => ({
  fontFamily: 'Arial',
  // fontSize: '0.5rem',
  textAlign: 'justify',
  width: '96%',
  maxWidth: '1200px',
  margin: 'auto',
  fontWeight: 'thin',
  paddingBottom: '8px',
  fontSize: '0.8rem',
}));

// Define the component
export const CampInfoCard = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const PdfModal = ({ open, handleClose }) => {
    const handleBackdropClick = (event) => {
      if (event.target === event.currentTarget) {
        handleClose(); // Close the modal only if the backdrop is clicked directly
      }
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ invisible: true, onClick: handleBackdropClick }}
      >
        <div>
          <div className="modalContent" style={{ zIndex: 10000 }}>
            <iframe
              src={`${Rulebook}`}
              style={{
                position: 'absolute',
                width: '80%',
                height: '80%',
                marginTop: '10vh',
                marginLeft: '10vw',
                zIndex: 9999,
                border: 0,
              }}
              title="waiverForm"
            ></iframe>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                zIndex: 0,
              }}
              className="customBackdrop"
              onClick={handleClose}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <StyledCard>
      <CardContent>
        <StyledTypography
          variant="h6"
          sx={{
            textAlign: 'center',
            fontWeight: 'medium',
          }}
        >
          LEARN FROM AND INTERACT WITH CFL ATHLETES, & HIGHLY SKILLED FOOTBALL
          COACHES!
        </StyledTypography>
        <StyledTypography variant="body2">
          JP7s will be hosting James Peter’s youth football skill camp and flag
          football showcase
        </StyledTypography>
        <StyledTypography variant="body2">
          Whether your athlete is new to the game, or an experienced athlete
          looking to build on his or her skills, let us help you take your game
          to the NEXT LEVEL!
        </StyledTypography>
        <StyledTypography variant="body2">
          James Peter 7s coaching staff will offer instruction to campers
          specializing in speed techniques, offensive/defensive fundamental
          football, and character-building skill development. Campers will be
          placed in small groups with other campers of the same age and ability
          level to ensure that each camper will receive maximum instruction from
          our highly skilled coaches. Your young athlete will leave this camp a
          better football player and it will be a great start to the upcoming
          football season.
        </StyledTypography>

        <div style={{ width: '100%', marginTop: '2%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={JP7Graphic}
                width="100%"
                maxWidth="1200px"
                textAlign="center"
                margin="2% auto"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTypography
                variant="h6"
                sx={{ textDecoration: 'underline' }}
              >
                Camp Details
              </StyledTypography>
              <StyledTypographyList variant="body2">
                • Youth skills camp date: Saturday, April 20 2024
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Youth flag showcase date: Sunday, April 21 2024
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Camp Start Time: 10am - 4:00pm
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Camp Check-in Time: 8:45am - 9:45am (Check-in will close
                promptly at 9:45am)
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Location: Matt Anthony Field at the University of Ottawa, 801
                King Edward, Ottawa, Ontario.
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Open to: Boys & Girls, ages 8-18
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Fee: Youth skills camp $59.99
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Fee: Youth flag showcase $69.99
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Camp Registration Closes on Sunday, April 7th, 2024
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Parents are welcome to watch the regular camp
              </StyledTypographyList>

              <StyledTypography
                variant="h6"
                sx={{ textDecoration: 'underline', textAlign: 'left' }}
              >
                Campers Will Receive
              </StyledTypography>
              <StyledTypographyList variant="body2">
                • On field instruction/interaction with Ottawa REBLACKS, James
                Peter & highly qualified camp instructors
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • A limited-edition JP7s camp t-shirt or Jersey
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • A limited-edition JP7s camp string bag
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Lunch
              </StyledTypographyList>
              <StyledTypographyList variant="body2">
                • Guest Speaker(s)
              </StyledTypographyList>
              <StyledTypography
                variant="h6"
                sx={{ textDecoration: 'underline', textAlign: 'left' }}
              >
                Position Stations Include
              </StyledTypography>

              <StyledTypographyList variant="body2">
                Quarterback, Wide Receiver, Running Back, Defensive Backs,
                Linebacker, Speed & Agility & Competition
              </StyledTypographyList>

              <StyledTypographyList
                variant="caption"
                sx={{ fontSize: '0.7rem', margin: '12px' }}
              >
                REFUND & DISCOUNT CODE POLICY:
              </StyledTypographyList>
              <StyledTypographyList variant="body2" sx={{ fontSize: '0.7rem' }}>
                James Peter 7s has full confidence that our April 20-21, 2024
                JP7s Camp will happen. We have a no refund policy once you pay
                the registration fee. Thank you.
              </StyledTypographyList>
              <Button>
                <StyledTypographyList
                  variant="body2"
                  sx={{ fontSize: '0.7rem' }}
                  onClick={handleModalOpen}
                >
                  Rules
                </StyledTypographyList>
                <PdfModal open={modalOpen} handleClose={handleModalClose} />
              </Button>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </StyledCard>
  );
};
