import React from 'react';
import { CampInfoCard } from '../components/CampInfoCard';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import coaches from '../public/coaches.png';
import { Footer } from '../components/Footer';

const StyledCampInfo = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  height: `calc(100vh - 72px)`,
  position: 'relative',
  overflow: 'auto',
  background: 'radial-gradient(circle, rgb(220,220,220), rgb(220,220,220))', // Radial gradient background
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const StyledBox = styled(Box)(({ theme }) => ({
  width: '80%',
  maxWidth: '1200px',
  [theme.breakpoints.down('md')]: {
    width: '90%', // Change width to 96% on small screens
  },
  textAlign: 'center',
  margin: 'auto',
  marginBottom: '2%',
}));

export const CampInfo = () => {
  return (
    <StyledCampInfo>
      <Typography
        variant="h5"
        sx={{
          fontFamily: 'Trebuchet MS',
          textAlign: 'center', // Center align the title
          maxWidth: '1000px',
          margin: 'auto',
          marginBottom: '2%',
          padding: '1% 4% 0% 4%',
          color: 'rgba(32,32,32)',
        }}
      >
        Camp Information
      </Typography>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <StyledBox component="img" src={coaches} />
      </div>
      <CampInfoCard />;
      <Footer />
    </StyledCampInfo>
  );
};
