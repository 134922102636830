// Import necessary dependencies
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Define the styled component
const StyledAboutCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  paddingLeft: '2%',
  margin: '2% auto',
  maxWidth: '1400px',
  width: '90%',
  border: '2px solid #ccc',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Trebuchet MS !important',
}));

// Define the component
export const AboutCard = () => {
  return (
    <StyledAboutCard>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Trebuchet MS',
            textAlign: 'center', // Center align the title
            marginBottom: '2%',
          }}
        >
          About James Peter 7s
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Arial',
            fontSize: 'small',
            textAlign: 'justify',
            width: '96%',
          }}
        >
          James Peter 7s (JP7s) is a youth and black-led nonprofit organization
          in Ottawa, Ontario. Established by James Peter, an Ottawa-native and
          member of the RedBlacks, the organization’s mission is to support
          youth athletes of all backgrounds while also providing youth the
          resources to pursue athletics in post-secondary. Our organization's
          aim is to enhance youth’s access to sports through development
          opportunities and mentorship, fostering overall improvement in
          athletic skills. Our first showcase will be held on April 20th and
          April 21st, at the Matt Anthony Field of the University of Ottawa,
          located at 801 King Edward, Ottawa, Ontario. The event is for youth
          aged 8-18 of all genders and is committed to fostering an inclusive
          environment that accommodates all participants. At our showcase,
          participants will have the opportunity to receive coaching from
          professionals associated with the Ottawa RedBlacks and the University
          of Ottawa. In addition to honing their football skills, participants
          will have the opportunity to be scouted at the university level,
          paving the way for potential sports opportunities in the future.
          {/* Our mission is centered on the
            holistic development of young athletes in the Ottawa region. We aim
            to provide them with a platform to showcase their talents while
            offering them the prospect of accessing sports opportunities at the
            university level. Join us in empowering the next generation of
            football stars in Ottawa! Our showcase is dedicated to providing a
            platform for boys and girls ages 10 to 18 in Ottawa to develop their
            skills through the introduction of 7on7 touch football. In
            collaboration with the University of Ottawa and the Ottawa
            Redblacks, we are committed to breaking down barriers and promoting
            equal opportunities for youth in our community. At our showcase,
            participants will have the unique opportunity to receive elite
            coaching from professionals associated with the Ottawa Redblacks and
            the University of Ottawa. Not only will they enhance their football
            skills, but they will also have the chance to be scouted at the
            university level, opening doors to potential sports opportunities in
            the future. */}
        </Typography>
      </CardContent>
    </StyledAboutCard>
  );
};
