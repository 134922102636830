// Import necessary dependencies
import React from 'react';
import {
  Card,
  CardContent,
  // Button,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import { styled } from '@mui/system';
// import { Link } from 'react-router-dom';
import jamesTeaching from '../public/james_teaching.jpeg';
import { Footer } from './Footer';

// Define the styled component
const StyledSignUpCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(220, 220, 220, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  padding: '2%',
  width: '60%',
  margin: 'auto',
  marginTop: '1rem',
  borderRadius: '8px',
  border: '2px solid #ccc',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  textAlign: 'center', // Center the content
  [theme.breakpoints.down('md')]: {
    width: '80%', // Change width to 96% on small screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%', // Change width to 96% on small screens
  },
}));

// Define the styled button
// const StyledButton = styled(Button)(({ theme }) => ({
//   fontSize: 'small',
//   textTransform: 'none',
//   width: '100%', // Make the buttons the same size
//   '&:hover': {
//     backgroundColor: theme.palette.primary, // Example: Change background color on hover
//   },
// }));

const StyledTypography = styled(Typography)(() => ({
  fontFamily: 'Arial',
  // fontSize: '0.5rem',
  width: '96%',
  maxWidth: '1200px',
  margin: 'auto',
  fontWeight: 'thin',
  color: 'rgba(32,32,32,1)',
}));

export const SignUpCard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledSignUpCard>
          <CardContent>
            <StyledTypography
              variant="h5"
              mb={1}
              sx={{ textAlign: 'center', marginBottom: '1rem' }}
            >
              WELCOME TO JAMES PETER 7s FOOTBALL
            </StyledTypography>

            <StyledTypography
              variant="body2"
              sx={{ textAlign: 'justify', marginBottom: '1rem' }}
            >
              James Peter 7s is the leading destination for youth football! We
              aim to create top-tier 7v7 youth flag camp experiences, where
              young athletes showcase their skills.
            </StyledTypography>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Box
                component="img"
                src={jamesTeaching}
                width="80%"
                maxWidth="1200px"
                textAlign="center"
                margin="auto"
                marginBottom="2%"
              />
            </div>
            <StyledTypography variant="body2" sx={{ textAlign: 'justify' }}>
              Kids have the opportunity to meet and learn from the best in
              football in a fun, relaxed and focused atmosphere. Our goal is to
              build a youth football community to foster and promote the love
              for the game of football!
            </StyledTypography>
          </CardContent>
        </StyledSignUpCard>
      </Grid>

      <Grid item xs={12}>
        {/* <StyledSignUpCard sx={{ marginBottom: '1rem', paddingTop: '1%' }}>
          <CardContent>
            <StyledTypography variant="h6" sx={{ marginBottom: '1em' }}>
              Registration
            </StyledTypography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div>
                  <StyledTypography
                    variant="subtitle2"
                    sx={{
                      minHeight: '3em',
                    }}
                  >
                    Youth Skills camp Boys/Girls ages 8-12
                  </StyledTypography>

                  <StyledButton
                    variant="contained"
                    color="primary"
                    size="small"
                    component={Link}
                    to="/SkillsCampRegistration"
                  >
                    Skills Camp
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <StyledTypography
                    variant="subtitle2"
                    sx={{ minHeight: '3em' }}
                  >
                    Youth Flag showcase Boys/Girls ages 13-18
                  </StyledTypography>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    size="small"
                    component={Link}
                    to="/ShowcaseRegistration"
                  >
                    Football Showcase
                  </StyledButton>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </StyledSignUpCard> */}
        <Footer />
      </Grid>
    </Grid>
  );
};
