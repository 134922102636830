import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { SkillsCampRegistration } from './pages/SkillsCampRegistration';
// import { ShowcaseRegistration } from './pages/ShowcaseRegistration';
import { TopBar } from './components/TopBar';
import { Home } from './pages/Home';
import { CampInfo } from './pages/CampInfo';
import { About } from './pages/About';

function App() {
  return (
    <Router>
      <Fragment>
        <TopBar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/campInfo" element={<CampInfo />} />
          {/* <Route
            exact
            path="/SkillsCampRegistration"
            element={<SkillsCampRegistration />}
          />
          <Route
            exact
            path="/showcaseRegistration"
            element={<ShowcaseRegistration />}
          /> */}
        </Routes>
      </Fragment>
    </Router>
  );
}

export default App;
