import React from 'react';
import { styled } from '@mui/system';
import { Email, Instagram, Facebook, Link } from '@mui/icons-material';

const FooterContainer = styled('div')(() => ({
  backgroundColor: 'transparent',
  padding: '1rem',
  textAlign: 'center',
  position: 'flex',
  bottom: 0,
  width: '100%',
  zindex: '100000000',
}));

const FooterContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FooterLink = styled('a')(() => ({
  color: '#000',
  textDecoration: 'none',
  margin: '0 1rem',
}));

const FooterIcon = styled('div')(() => ({
  fontSize: '1.5rem',
}));

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterIcon>
          <FooterLink href="mailto:JP7sfootball@gmail.com">
            <Email color="#fff" />
          </FooterLink>
        </FooterIcon>
        <FooterIcon>
          <FooterLink href="https://www.instagram.com/jamespeter7s?igsh=MXZvbDcyMXVoNTlxOA%3D%3D&utm_source=qr">
            <Instagram />
          </FooterLink>
        </FooterIcon>
        <FooterIcon>
          <FooterLink href="https://www.facebook.com/share/qzFEP4QLaGTQHR2e/?mibextid=hu50Ix">
            <Facebook />
          </FooterLink>
        </FooterIcon>
        <FooterLink href="https://linktr.ee/JamesPeter7sFootball?utm_source=linktree_profile_share&ltsid=30d704b3-2ed3-418e-af66-8622eac3b5fe">
          <Link />
        </FooterLink>
      </FooterContent>
    </FooterContainer>
  );
};
