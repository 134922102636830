import React from 'react';
import { AboutCard } from '../components/AboutCard';
import { styled } from '@mui/system';
import jamesTackle from '../public/james_tackle.png';
import { Footer } from '../components/Footer';

const StyledAbout = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: `calc(100vh - 72px)`,
  height: '100%',
  position: 'relative',
  overflow: 'auto',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundImage: `url(${jamesTackle})`,
    backgroundSize: 'cover',
    filter: 'blur(5px)',
    zIndex: -1,
  },
});

const StyledContainer = styled('div')({
  minHeight: `calc(100vh - 72px - 6rem)`,
});

export const About = () => {
  return (
    <StyledAbout>
      <StyledContainer>
        <AboutCard />
      </StyledContainer>
      <Footer />
    </StyledAbout>
  );
};
